<template>
  <div class="text-2xl font-bold text-invest-primary">Withdraw Investment</div>
  <p class="mt-2 mb-5 text-xs text-invest-dark">
    What account would you want to withdraw funds to?
  </p>

  <!-- <selection :method="method" @click="selectMethod('new-account')">
    <div class="flex flex-col ml-4">
      <h2 class="mt-1 text-xs font-semibold text-invest-darkblue">
        New Bank Account
      </h2>
    </div>
  </selection> -->

  <template v-if="loading">
    <skeleton-loader
      width="10px"
      height="60px"
      class-name="rounded w-full mt-5"
      v-for="i in 3"
      :key="i"
    />
  </template>
  <template v-else-if="accounts.length">
    <selection
      :method="method"
      v-for="(account, index) in accounts"
      :key="index"
      :account="account"
      @click="selectMethod(account)"
    />
  </template>
  <template v-else>
    <div class="flex flex-col">
      <p class="mt-3 text-10 text-invest-dark">
        You currently have no bank accounts.
      </p>
    </div>
  </template>

  <div
    class="grid grid-cols-1 place-content-end"
    :class="accounts.length > 2 || accounts.length === 0 ? 'h-32' : 'h-64'"
  >
    <button
      type="button"
      class="btn btn-primary"
      @click="proceed"
      :disabled="!method"
    >
      Continue
    </button>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  SetupContext,
  onBeforeMount,
} from 'vue';
import { getBankAccounts } from '@/modules/requests';
import { AccountProp } from '@/types/Account';

export default defineComponent({
  components: {
    Selection: defineAsyncComponent(() => import('./Selection.vue')),
  },
  inheritAttrs: false,
  emits: ['navigateToAddAccount', 'navigateToConfirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const method = ref<string>('');
    const loading = ref<boolean>(false);

    const accounts = ref<AccountProp[]>([]);
    const account = ref<any>({} as AccountProp);

    const selectMethod = (selected: any) => {
      switch (selected?.constructor) {
        case String:
          method.value = selected;
          break;
        case Object:
          account.value = selected;
          method.value = selected?.id;
          break;
      }
    };

    const bankAccounts = () => {
      loading.value = true;
      getBankAccounts()
        .then((response) => (accounts.value = response?.data))
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    };

    onBeforeMount(() => bankAccounts());

    const proceed = () => {
      if (method.value === 'new-account') emit('navigateToAddAccount');
      else emit('navigateToConfirmWithdraw', account.value);
    };

    return { method, selectMethod, proceed, loading, accounts };
  },
});
</script>
