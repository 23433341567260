
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  SetupContext,
  onBeforeMount,
} from 'vue';
import { getBankAccounts } from '@/modules/requests';
import { AccountProp } from '@/types/Account';

export default defineComponent({
  components: {
    Selection: defineAsyncComponent(() => import('./Selection.vue')),
  },
  inheritAttrs: false,
  emits: ['navigateToAddAccount', 'navigateToConfirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const method = ref<string>('');
    const loading = ref<boolean>(false);

    const accounts = ref<AccountProp[]>([]);
    const account = ref<any>({} as AccountProp);

    const selectMethod = (selected: any) => {
      switch (selected?.constructor) {
        case String:
          method.value = selected;
          break;
        case Object:
          account.value = selected;
          method.value = selected?.id;
          break;
      }
    };

    const bankAccounts = () => {
      loading.value = true;
      getBankAccounts()
        .then((response) => (accounts.value = response?.data))
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    };

    onBeforeMount(() => bankAccounts());

    const proceed = () => {
      if (method.value === 'new-account') emit('navigateToAddAccount');
      else emit('navigateToConfirmWithdraw', account.value);
    };

    return { method, selectMethod, proceed, loading, accounts };
  },
});
